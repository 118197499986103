import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

const ConsumerSupport = (props) => {
  return (
    <>
      <Modal.Header closeButton>Consumer Support</Modal.Header>
      <Modal.Body>
        <h4 className="consumer-support-title">
          Need help feeling overwhelmed or vulnerable?
        </h4>

        <div className="consumer-support-container">
          <h3 className="mb-2">Citizens Advice</h3>
          <p className="mb-2">Charity offering free, confidential advice.</p>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <h4 className="mb-2">Adviceline (England)</h4>
              <a
                href="tel:08001448848"
                className="text-blue-600 hover:text-blue-800"
              >
                0800 144 8848
              </a>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="mb-2">Adviceline (England)</h4>
              <a
                href="tel:08007022020"
                className="text-blue-600 hover:text-blue-800"
              >
                0800 702 2020
              </a>
            </Col>
          </Row>

          <p className="mb-2">If you have a hearing or speech impairment.</p>

          <Row>
            <Col xs={12} md={6}>
              <h4 className="mb-2">Relay (England)</h4>
              <a
                href="tel:1800108001448848"
                className="text-blue-600 hover:text-blue-800"
              >
                18001 0800 144 8848
              </a>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="mb-2">Relay (England)</h4>
              <a
                href="tel:1800108007022020"
                className="text-blue-600 hover:text-blue-800"
              >
                18001 0800 702 2020
              </a>
            </Col>
          </Row>
        </div>

        <div className="consumer-support-container">
          <h3 className="mb-2">Money helper</h3>
          <p className="mb-2">
            Government backed free and impartial help with money & pensions
          </p>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <h4 className="mb-2">English</h4>
              <a
                href="tel:08001387777"
                className="text-blue-600 hover:text-blue-800"
              >
                0800 138 7777
              </a>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="mb-2">Welsh</h4>
              <a
                href="tel:08001380555"
                className="text-blue-600 hover:text-blue-800"
              >
                0800 138 0555
              </a>
            </Col>
          </Row>

          <p className="mb-2">If you have a hearing or speech impairment.</p>

          <Row>
            <Col xs={12} md={6}>
              <h4 className="mb-2">Type talk</h4>
              <a
                href="tel:1800108009154622"
                className="text-blue-600 hover:text-blue-800"
              >
                18001 0800 915 4622
              </a>
            </Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={props.onBack} className="outline">
          Go back
        </button>
        <button onClick={props.onProceed} className="dark">
          I understand
        </button>
      </Modal.Footer>
    </>
  );
};

export default ConsumerSupport;
