import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

import { submitQuote, updateBankingDetails } from "services";
import AffordabilityStep from "components/modals/quote/AffordabilityStep";
import ApplicationDeclined from "components/modals/quote/outcome/ApplicationDeclined";
import ApplicationPending from "components/modals/quote/outcome/ApplicationPending";
import ApplicationReferred from "components/modals/quote/outcome/ApplicationReferred";
import ApplicationSuccess from "components/modals/quote/outcome/ApplicationSuccess";
import CommissionStep from "components/modals/quote/CommissionStep";
import ComplianceSteps from "components/modals/quote/ComplianceSteps";
import ConsumerSupport from "components/modals/quote/ConsumerSupport";
import EmploymentStep from "components/modals/quote/EmploymentStep";
import FullQuoteStep from "components/modals/quote/FullQuoteStep";
import Loader from "components/Loader";

const SubmitToLenderModal = (props) => {
  const [applicationData, setApplicationData] = useState({});
  const [complianceQuestions, setComplianceQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("quote");

  useEffect(() => {
    if (props.show && props.quote?.status === "Submitted To Lender") {
      setStep("pending");
    }
  }, [props]);

  useEffect(() => {
    if (props.show) {
      setStep("quote");
    }
  }, [props.show]);

  const handleOnProceed = (values = {}, nextStep = null) => {
    let localApplicationData = {
      ...applicationData,
      ...values,
    };

    setApplicationData(localApplicationData);

    if (nextStep) {
      setStep(nextStep);
    } else {
      setLoading(true);
      updateBankingDetails(localApplicationData).then((res) => {
        submitQuote({
          quoteGuid: props.quote.quoteGuid,
          creditSearchDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
          .then((res) => {
            if (res?.applicationStatus == "ProposalSubmitted") {
              setStep("pending");
            } else if (res?.applicationStatus === "LenderDecicionRecived") {
              setStep(res?.quote?.status?.toLowerCase());
            }
          })
          .finally(() => setLoading(false));
      });
    }
  };

  if (!props.show) {
    return null;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      className={`blurred ${step !== "quote" ? "large" : ""}`}
    >
      {loading ? (
        <>
          <Modal.Header closeButton>
            Submitting application to lender...
          </Modal.Header>

          <Modal.Body className="submit-to-lender d-flex align-items-center justify-content-center flex-column">
            <Loader />

            <p className="info mt-4">
              We are submitting your application. Please wait...
            </p>
          </Modal.Body>
        </>
      ) : (
        <>
          {step === "quote" ? (
            <FullQuoteStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              onProceed={(values) => handleOnProceed(values, "employment")}
            />
          ) : step === "employment" ? (
            <EmploymentStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={(e) => setStep("quote")}
              onProceed={(values) => handleOnProceed(values, "affordability")}
              setComplianceQuestions={setComplianceQuestions}
            />
          ) : step === "affordability" ? (
            <AffordabilityStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={(e) => setStep("employment")}
              onProceed={(values) => handleOnProceed(values, "consumerSupport")}
            />
          ) : step === "consumerSupport" ? (
            <ConsumerSupport
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              onProceed={() => handleOnProceed({}, "compliance")}
            />
          ) : step === "compliance" ? (
            <ComplianceSteps
              questions={complianceQuestions}
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={(e) => setStep("affordability")}
              onProceed={() => handleOnProceed({}, "commission")}
            />
          ) : step === "commission" ? (
            <CommissionStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              onProceed={() => handleOnProceed({})}
            />
          ) : step === "accepted" || step === "approved" ? (
            <ApplicationSuccess
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : step === "pending" ? (
            <ApplicationPending
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              setStep={setStep}
            />
          ) : step === "referred" ? (
            <ApplicationReferred
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : step === "declined" ? (
            <ApplicationDeclined
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </Modal>
  );
};

export default SubmitToLenderModal;
