import React from "react";
import { Modal } from "react-bootstrap";

import { formatCurrency } from "helpers/currency";

const DealerCommissionModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className="product-info-modal blurred commission-modal"
      >
        <Modal.Header closeButton>Dealer Commission Disclosure</Modal.Header>
        <Modal.Body>
          {props.quote?.dealerCommissionCalculationMechanism ===
          "Percentage of Amount Financed" ? (
            <p>
              This dealer is paid a commission of £
              {formatCurrency(props?.quote?.dealerCommission, 2)} by{" "}
              {props?.lender?.lenderName}, which is based on the amount that the
              customer borrows.
            </p>
          ) : (
            <p>
              This dealer is paid a fixed commission of £
              {formatCurrency(props?.quote?.dealerCommission, 2)} by{" "}
              {props?.lender?.lenderName}, it is not based on the amount that
              the customer borrows and does not affect the interest rate that
              the customer will pay for this finance product.
            </p>
          )}

          <button className="green w-100" onClick={props.onHide}>
            I understand
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

DealerCommissionModal.defaultProps = {
  quote: null,
  lender: null,
};

export default DealerCommissionModal;
