import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { formatCurrency } from "helpers/currency";
import { complianceQuestionAudit } from "services/applications";
import DealerCommissionModal from "components/modals/DealerCommissionModal";

const CommissionStep = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const [showDealerCommissionModal, setShowDealerCommissionModal] =
    useState(false);

  const question = `I confirm that I have read the Commission Disclosure Document and understand the nature and existence of the commission of the £${formatCurrency(
    props.quote?.dealerCommission,
    0
  )}. Learn how this is calculated that will be paid to the dealer by ${
    props?.lender?.lenderName
  } if I proceed.`;

  const handleProceed = () => {
    complianceQuestionAudit(props.quote.quoteGuid, question).then(() => {
      props.onProceed();
    });
  };

  return (
    <>
      <Modal.Header closeButton>Submit application to lender</Modal.Header>
      <Modal.Body>
        <h4 className="compliance-title">Dealer Commission</h4>

        <p>
          I confirm that I have read the{" "}
          <span>Commission Disclosure Document</span> and understand the nature
          and existence of the commission of the £
          {formatCurrency(props.quote?.dealerCommission, 0)}.{" "}
          <a href="#" onClick={(e) => setShowDealerCommissionModal(true)}>
            <span>Learn how this is calculated</span>
          </a>{" "}
          that will be paid to the dealer by {props?.lender?.lenderName} if I
          proceed.
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={props.onBack} className="outline">
          Go back
        </button>
        <button onClick={handleProceed} className="dark">
          Submit application
        </button>
      </Modal.Footer>

      <DealerCommissionModal
        show={showDealerCommissionModal}
        onHide={() => setShowDealerCommissionModal(false)}
        quote={props.quote}
        lender={props.lender}
      />
    </>
  );
};

export default CommissionStep;
