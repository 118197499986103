import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { complianceQuestionAudit } from "../../../services/applications";
import ComplianceStep from "./ComplianceStep";

const ComplianceSteps = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleProceed = () => {
    complianceQuestionAudit(
      props.quote.quoteGuid,
      props.questions[currentQuestion]?.question
    ).then(() => {
      if (currentQuestion < props.questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        props.onProceed();
      }
    });
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else {
      props.onBack();
    }
  };

  return (
    <>
      <Modal.Header closeButton>Submit application to lender</Modal.Header>
      <ComplianceStep
        question={props.questions[currentQuestion]}
        onBack={handleBack}
        onProceed={handleProceed}
      />
    </>
  );
};

export default ComplianceSteps;
